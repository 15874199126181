import React from "react"
import Layout from "../../components/UI/layout"

const Philosophy = () => {
  return (
    <Layout>
      <div className="relative pb-20 pt-8 px-4 sm:px-6 lg:pb-28">
        <div className="absolute inset-0">
          <div className="h-1/3 sm:h-2/3" />
        </div>
        <div className="relative max-w-7xl mx-auto">
          <div className="text-center">
            <h2 className="text-3xl tracking-tight font-extrabold text-white sm:text-4xl">
              Investment philosophy
            </h2>
            <p className="mt-3 max-w-4xl mx-auto text-left text-xl text-gray-300 sm:mt-4">
              We believe in a digital future. And that digital assets will play
              a dominant role in this future. Based on this thesis,
              cryptocurrencies will continue their rise as an emerging asset
              class
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Philosophy
